import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ArticlesListing from 'components/ArticlesListing';
import BannerWithSimpleCards from 'components/BannerWithSimpleCards';
import Breadcrumbs from 'components/Breadcrumbs';

import { PressReleasesPageTypes } from './models';

import './PressReleasesPage.scss';

const PressReleasesPage: FC<PressReleasesPageTypes.PressReleasesPageType> = ({
  data: { commonFields, pressReleasesPage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    pressReleasesPage.seo;
  const {
    pageId,
    level,
    lang,
    title,
    banner,
    popularTopics,
    helperFields,
    numberOfArticlesPerLoad,
    url,
  } = pressReleasesPage;
  const { articleReadingTimeText, articleWrittenByText } = commonFields;

  return (
    <Layout headerTransparent className="press-releases">
      <Seo
        {...{
          seo: pressReleasesPage.seo,
          openGraph: pressReleasesPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="banners">
        <Breadcrumbs level={level} />
        <BannerWithSimpleCards {...banner} />
      </div>

      <ArticlesListing
        pageId={pageId}
        numberOfArticlesPerLoad={numberOfArticlesPerLoad}
        {...helperFields}
        popularTopics={popularTopics}
        lang={lang}
        readingTimeText={articleReadingTimeText}
        writtenByText={articleWrittenByText}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    commonFields {
      articleReadingTimeText
      articleWrittenByText
    }
    pressReleasesPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentBannerWithSimpleCards
      }
      popularTopics {
        ...FragmentPopularTopics
      }
      helperFields {
        ...FragmentArticlesFiltersExtendedHelperFields
      }
      numberOfArticlesPerLoad
    }
  }
`;

export default PressReleasesPage;
